.profile {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  margin: 1rem;
  padding: 1rem;
  // height:100%;
  background-color: white;
  flex-direction: column;
}
.profile > div {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.profile__right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.profile__right > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}