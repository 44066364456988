* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* custom item img to component upload antd */
.ant-upload-list-item-thumbnail > img {
  object-fit: contain !important;
}

/* custom size component upload in print file product type */
.upload-print-file > .ant-upload {
  width: 65px !important;
  height: 65px !important;
}

/* custom color text component progress antd */
.ant-progress-text {
  color: white !important;
}

/* disabled scroll tabs header in order */
.ant-tabs-nav-wrap {
  overflow: unset !important;
}

/* custom active tab in orders item */
.ant-badge {
  color: unset !important;
}

.ant-tabs {
  overflow: unset !important;
}

/* set background color row table child in orders pending design */
.product-unsign-approve {
  background-color: rgb(23, 209, 23) !important;
}

.product-unsign-pending {
  background-color: orange !important;
}

.product-unsign-uncheck {
  background-color: rgb(133, 197, 250) !important;
}

.product-unsign-cancel {
  background-color: rgb(255, 175, 175) !important;
}
/* set background color row table child in orders pending design */

/* disabale hover table  */
.product-unsign-approve:hover > td {
  background: unset !important;
}

.product-unsign-pending:hover > td {
  background: unset !important;
}

.product-unsign-uncheck:hover > td {
  background: unset !important;
}

.product-unsign-cancel:hover > td {
  background: unset !important;
}
/* disabale hover table  */

.ant-upload-list-item-actions {
  display: flex !important;
  align-items: center !important;
}

.print-file-product-order .ant-upload-list-item-actions {
  opacity: 1 !important;
}

.print-file-product-order .ant-upload-list-item-info::before {
  opacity: 1 !important;
}

.print-file-product-order .ant-upload-list-item-card-actions-btn {
  opacity: 1 !important;
}


