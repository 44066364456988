.content_setting {
    background-color: white;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}
.content_setting_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;
    border-bottom: 1px solid rgb(209, 193, 193);
    width: 100%;
}
.content_setting_title > div {
    color:black;
    font-weight: 600;
    margin-right: 0.5rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
}
.content_setting_parent {
    margin-top: 0rem;
}
.tab1 {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 100%;
}
.tab1_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
.tab1_left_menu {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    cursor: pointer;
    font-weight: 600;
    color: black;
    height: 2.5rem;
    margin-bottom: 1rem;
}
.tab1_left_menu_active {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    color: black;
    width: 100%;
    cursor: pointer;
    height: 2.5rem;
    border-right: 2.5px solid #1492FF;
    margin-bottom: 1rem;
}
.tab1_left_menu_active:hover {
    background-color: #1492FF;
    color: white;
    z-index: 999;
}
.tab1_left_menu:hover {
    background-color: #1492FF;
    color: white;
    z-index: 999;
}