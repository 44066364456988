.users_manager {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    margin: 1rem;
    padding: 1rem;
}
.users_manager_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid rgb(211, 191, 191);
    padding-bottom: 1rem;
}
.users_manager_title_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.users_manager_title_row_col {
    width: 100%;
}
.users_manager_title_row_col_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 1005;
}
.users_manager_title_row_col_button {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    align-items: center;
    width: 100%;
}
.users_manager_title_row_col_title > div {
    color: black;
    font-size: 1rem;
    font-weight: 600;
}
.users_manager_title_row_col_button > div {
    color: black;
    font-size: 1rem;
    font-weight: 600;
}
.users_manager_note {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
}
.users_manager_note_top {
    color: black;
    font-weight: 600;
}
.users_manager_note > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.users_manager_table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    max-width: 100%;
    margin-top: 1rem;
    border: 1px solid rgb(214, 196, 196);
    overflow: auto;
    background-color: white;
}
.users_manager_table_child {
    width: 100%;
    background-color: white;
}