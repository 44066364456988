.login {
  background-color: #f0f2f5;
  width: 100vw;
  height: 100vh;
  background-image: url('../../assets/img/layer.jpg');
  background-repeat: no-repeat;
  background-size: cover;
}

.login_img {
  width: 15rem;
  height: 10rem;
  object-fit: contain;
}

.login_img_parent {
  display: flex;
  padding: 2rem 0 1rem 0;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.login_img_parent > div {
  font-size: 2.5rem;
  font-weight: 900;
  color: #1890ff;
}
.login_choose_parent {
  width: 100%;
  display: flex;
  margin-bottom: 1rem;
  justify-content: center;
  align-items: center;
}
.login_choose {
  display: flex;
  width: 10rem;
  justify-content: space-between;
  align-items: center;
}

.login_choose_login {
  margin-right: 3rem;
}

.login_choose > div {
  cursor: pointer;
}
.login_choose > div:hover {
  color: #24a7ec;
}
.login_choose_status_active {
  padding-bottom: 1rem;
  border-bottom: 2px solid #24a7ec;
  font-size: 1.25rem;
  color: #24a7ec;
  height: 2.5rem;
  cursor: pointer;
}
.login_choose_status {
  /* padding-bottom: 1rem;
      border-bottom: 2px solid #24a7ec; */
  font-size: 1.25rem;
  height: 2.5rem;
  cursor: pointer;
  color: white;
}
.login_bottom {
  display: flex;
  width: 100%;
  margin-top: 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.login_bottom_left > div {
  color: #24a7ec;
  font-size: 1rem;
  cursor: pointer;
  font-weight: 600;
}
.login_bottom_left_checkbox {
  color: white;
  font-size: 1.5rem;
}
.login_bottom_left_button_parent {
  width: 100%;
  display: flex;

  justify-content: center;
  align-items: center;
}
.login_bottom_left_button_parent_re_send {
  width: 22.5rem;
  margin-bottom: 1.5rem;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
}
@media only screen and (min-width: 320px) {
  .login_bottom_email {
    width: 18.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 18.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 18.5rem;
  }
  .login_bottom_left_button {
    width: 18.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 300px) {
  .login_bottom_email {
    width: 16.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 16.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 16.5rem;
  }
  .login_bottom_left_button {
    width: 16.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}

@media only screen and (min-width: 380px) {
  .login_bottom_email {
    width: 20.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 20.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 20.5rem;
  }
  .login_bottom_left_button {
    width: 20.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 576px) {
  .login_bottom_email {
    width: 22.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 768px) {
  .login_bottom_email {
    width: 22.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 992px) {
  .login_bottom_email {
    width: 22.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 1200px) {
  .login_bottom_email {
    width: 22.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    // padding-bottom: 0.75rem;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
@media only screen and (min-width: 1600px) {
  .login_bottom_email {
    width: 22.5rem;
    display: flex;
    justify-content: space-between;
    align-content: center;
  }
  .login_bottom_password {
    width: 22.5rem;
  }
  .login_bottom_left {
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 22.5rem;
  }
  .login_bottom_left_button {
    width: 22.5rem;
    font-size: 1.25rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
    height: 2.5rem;
  }
}
.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-size: 1rem;
  /* font-weight: 600; */
  color: white;
  margin-bottom: 1.5rem;
  position: fixed;
  bottom: 0;
  /* height: 50%; */
}
.login_bottom_left_forgot_password {
  margin-top: 0.25rem;
}
.back_to_login {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  color: #1890ff;
  font-weight: 600;
  // padding-bottom: 1rem;
  cursor: pointer;
  font-size: 1rem;
  width: 22.5rem;
}
.login_title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.login_title_top {
  color: white;
  display: flex;
  font-size: 1.25rem;
  text-align: center;
  justify-content: center;
  margin-bottom: 1rem;
  align-items: center;
  width: 22.5rem;
  font-weight: 600;
}
.login_title_bottom {
  color: white;
  display: flex;
  margin-bottom: 0.5rem;
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  width: 22.5rem;
  font-weight: 600;
}
.login_title_bottom_email {
  color: white;
  display: flex;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  text-align: center;
  justify-content: center;
  font-size: 1rem;
  align-items: center;
  width: 22.5rem;
  font-weight: 600;
}
.login_bottom_email_col {
  width: 100%;
}
.login_bottom_left_button_parent_left {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  font-weight: 600;
  font-size: 1rem;
  color: white;
}
.login_bottom_left_button_parent_right {
  display: flex;
  margin-left: 0.5rem;
  justify-content: flex-start;
  align-content: center;
  font-weight: 600;
  font-size: 1rem;
  color: #1890ff;
}
.loading {
  display: flex;
  visibility: visible;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.loading_active {
  display: flex;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 999;
  position: fixed;
}
.spinner-3 {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, #25b09b);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
