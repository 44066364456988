.product_add {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 1rem;
  flex-direction: column;

  .loading-progress {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 50000;

    .overlay-progress {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: black;
      opacity: 0.5;
    }
  }
}
.product_add_title {
  display: flex;
  // margin-bottom: 1rem;
  cursor: pointer;
  // background-color: red;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // background-color: white;
  // padding: 1rem;
}
.product_add_title > div {
  margin-right: 0.5rem;
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.product_add_title_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_title_row_col {
  width: 100%;
}
.product_add_title_row_col_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_title_row_col_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
}
.product_add_title_row_col_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_title_row_col_left > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
}
.product_add_content_content {
  display: flex;
  margin-bottom: 1rem;
  flex-direction: column;
  border: 1px solid #f0f0f0;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_content_content_row {
  display: flex;
  padding: 2.75rem 2.75rem 2.75rem 2.75rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_content {
  display: flex;
  margin-top: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.product_add_content_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_content_parent_row {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.product_add_content_parent_row_col {
  width: 100%;
  color: #2c93f1;
  font-size: 1rem;
  cursor: pointer;
  // margin-right: 1rem;
  margin-bottom: 1rem;
}
.product_add_content_parent_row_col_active {
  width: 100%;
  color: #2c93f1;
  font-weight: 600;
  // border-bottom: 1px solid #2c93f1;
  // padding-bottom: 0.5rem;
  font-size: 1.25rem;
  cursor: pointer;
  // margin-right: 1rem;
  margin-bottom: 1rem;
}
.product_add_content_parent_row_col:hover {
  color: black;
}
.product_add_content_parent_row_col_button {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  // width: 100%;
}
.product_add_content_parent_row_col_top {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.product_add_content_parent_row_col_top_parent {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.product_add_content_content_row_col {
  width: 100%;
  position: relative;
  margin-right: 2.75rem;
  cursor: pointer;
  margin-bottom: 2.75rem;
  //   margin: 1rem 0 0 1rem;
  border: 1px solid #f0f0f0;
  //   margin-bottom: 1rem;
  transition: all .3s;
}
.product_add_content_content_row_col:hover {
  box-shadow: -2px 0px 24px 0px rgba(0, 0, 0, 0.38);
  -webkit-box-shadow: -2px 0px 24px 0px rgba(0, 0, 0, 0.38);
  -moz-box-shadow: -2px 0px 24px 0px rgba(0, 0, 0, 0.38);
}
.product_add_content_content_row_col_item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  min-height: 330px;
  max-height: 330px;
}
.product_add_content_content_row_col_item_bottom {
  height: 100px;
  max-height: 100px;
  display: flex;
  background-color: #f5f5f5;
  padding: 0.75rem 1rem 1rem 1rem;
  width: 100%;
  flex-direction: column;
}
.product_add_content_content_row_col_item_bottom_title {
  color: black;
  font-size: 1.25rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.product_add_content_content_row_col_item_bottom_title_price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: gray;
}
.product_add_content_content_row_col_item_image_child {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product_add_content_content_row_col_item_image_parent {
  width: 100%;
  min-height: calc(330px - 80px);
  max-height: calc(330px - 80px);
  height: calc(330px - 80px);
  padding: 1rem;
}
.product_add_content_content_title {
  color: black;
  width: 100%;
  font-weight: 600;
  padding: 1rem;
  // padding-bottom: 1rem;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1rem;
}
.input_modal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
}
.input_modal_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
  flex-direction: column;
}
.modal {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.modal_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.modal_row_parent {
  display: flex;
  // margin-top: 1rem;
  border-bottom: 1px solid rgb(216, 203, 203);
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.modal_row_type {
  display: flex;
  // padding-top: 1rem;
  margin-top: 1.25rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.modal_row_col {
  width: 100%;
}
.modal_row_col_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // color: black;
  // font-size: 1rem;
  // font-weight: 600;
  margin-bottom: 0.5rem;
}
.modal_select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.modal_upload {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal_button {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.modal_button_cancel {
  margin-right: 1rem;
}
.modal_upload_title {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.product_add_content_content_row_col_item_click {
  position: absolute;
  display: block;
  top: 0;
  right: 0;
}
.product_add_content_content_row_col_item_click_active {
  position: absolute;
  display: none;
  top: 0;
  right: 0;
}
.product_add_content_select {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.product_add_content_select_row {
  display: flex;
  justify-content: space-between;
  // align-content: center;
  width: 100%;
}
.product_add_content_select_row_col {
  width: 100%;
  margin-bottom: 1rem;
}
.product_add_content_select_row_col_title {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  // color: black;
  // font-size: 1.25rem;
  // font-weight: 600;
}
.product_add_content_select_row_col_button {
  display: flex;
  justify-content: flex-end;
  align-content: center;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 600;
}
.modal_detail {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
}
.modal_detail_row {
  display: flex;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}
.modal_detail_row_col_right {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_right_parent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
}
.modal_detail_row_col_right_parent > div {
  // color: black;
  font-size: 1rem;
}
.modal_detail_row_col_right_parent_title {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  padding: 1rem;
  border: 1px solid #f0f0f0;
}
.modal_detail_row_col_right_parent_bottom {
  // display: flex;
  // justify-content: center;
  // background-color: red;
  // align-content: center;
  width: 100%;
  border-right: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
  // padding: 1rem 1rem 0rem 1rem;
}
.modal_detail_row_col_parent {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid #f0f0f0;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_parent_bottom {
  display: flex;
  margin-bottom: 1rem;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid #f0f0f0;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_parent_title {
  font-size: 1rem;
  padding: 1rem;
  font-weight: 600;
  color: black;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}
.modal_detail_row_col_parent {
  display: flex;
  margin-bottom: 1rem;
  padding: 1rem;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid #f0f0f0;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_parent_row {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_parent_row_fix {
  display: flex;
  // padding: 1rem;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_parent_row_col {
  width: 100%;
}
.modal_detail_row_col_parent_row_col_input {
  width: 100%;
}
.modal_detail_row_col_parent_row_col_title {
  margin-bottom: 0.5rem;
}
.modal_detail_row_col_parent_bottom_images {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 100%;
}
.modal_detail_row_col_parent_bottom_upload_images {
  padding: 1rem;
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  flex-direction: column;
}
.modal_detail_row_col_title {
  font-size: 1rem;
  color: #40a9ff;
  font-weight: 600;
  border-bottom: #40a9ff;
  // padding-bottom: 1rem;
  margin-bottom: 1rem;
}
.modal_detail_row_col_title_parent {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
}
.modal_detail_row_col_parent_bottom_upload_images_table_parent {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  border: 1px solid rgb(207, 193, 193);
  width: 100%;
  background-color: white;
  max-width: 100%;
  overflow: auto;
}
.modal_detail_row_col_parent_bottom_upload_images_table_child {
  background-color: white;
  width: 100%;
}
.checkbox_note {
  display: flex;
  justify-content: flex-start;
  align-content: center;
  width: 100%;
  padding-bottom: 1rem;
  color: black;
  border-bottom: 1px solid rgb(219, 199, 199);
  font-size: 1rem;
  margin-top: 1rem;
}
.button_modal {
  display: flex;
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgb(211, 195, 195);
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.button_modal > div {
  // display: flex;
  // justify-content: flex-end;
  // align-content: center;
  // width: 100%;
  margin-right: 1rem;
}
.button_modal_center {
  // margin: 0 1rem;
}
.modal_editor {
  width: 100%;
  max-width: 100%;
  overflow: auto;
}
.variant {
  display: flex;
  justify-content: flex-start;
  // align-items: center;
  width: 100%;
  // margin-bottom: 1rem;
  color: black;
  font-size: 1rem;
  font-weight: 600;
}
.variant_parent {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.variant_parent_fix {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.variants_fix {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #f0f0f0;
  width: 100%;
}
.variants_fix_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: black;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem;
  border-bottom: 1px solid #f0f0f0;
}
.variants_fix_body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.variants_fix_body_row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  // margin: 1rem;
  padding: 1rem 0rem 0 0rem;
  width: 100%;
  border-bottom: 1px solid #f0f0f0;
}
.variants_fix_body_row_fix {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  margin-top: 1rem;
  // padding-bottom: 0.25rem;
  width: 100%;
  // border-bottom: 1px solid rgb(219, 207, 207);
}
.variants_fix_body_row_col {
  width: 100%;
  margin-bottom: 1rem;
}
.variants_fix_body_row_col > div {
  color: black;
  font-weight: 600;
}
.attributes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.attributes > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.attributes_row {
  display: flex;
  padding: 0 1rem;
  border-bottom: 1px solid rgb(223, 206, 206);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.variant_add {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
.variant_add > div {
  display: flex;
  font-weight: 600;
  font-size: 1rem;
  color: #FE5E06;
  justify-content: flex-end;
  align-items: center;
  // width: 100%;
}
.variants_new_change {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
  border: 1px solid #f0f0f0;
}
.variants_new_change > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.variants_new_change_title {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color:black;
  font-weight: 600;
  font-size: 1rem;
  padding: 1rem 1rem 1rem 1rem;
  // border-bottom: 1px solid #f0f0f0;
  // margin-bottom: 1rem;
}
.add_variants_new {
  display: flex;
  cursor: pointer;
  margin-right: 1rem;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.add_variants_new > div {
  color: #FE5E01;
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
}
.preview {
  margin-top: 1rem;
  // padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border: 1px solid #f0f0f0;
}
.supplier_cost {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.supplier_cost > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}