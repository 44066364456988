.orders_detail_main {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem;
}
.orders_detail_main_parent {
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.orders_detail_main > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
  margin-right: 0.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.orders_detail_main_parent_content {
  // margin-top: 1rem;
  // padding: 1rem;
  display: flex;

  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.hover_image:hover {
  z-index: 99;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgb(219, 195, 195);
}
.eye {
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, -50%);
  text-align: center;
}
.eye_icon {
  position: absolute;
  left: 35%;
  top: 45%;

  transform: translate(-45%, -35%);
  text-align: center;
}
.eye_icon_right {
  position: absolute;
  left: 70%;
  top: 55%;

  transform: translate(-55%, -70%);
  text-align: center;
}
.hover_image:hover > img {
  opacity: 1;
  display: none;
}
.hover_image:hover > div {
  opacity: 1;
  display: block;
}
.orders_detail_main_parent_content_fulfill {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem 1rem 0.5rem 1rem;
  width: 100%;
  margin-bottom: 1rem;
  background-color: white;
  flex-direction: column;
}
.orders_detail_main_parent_content_fulfill > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: black;
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.orders_detail_main_parent_content_fulfill_table {
  border: 1px solid rgb(224, 208, 208);
}

.tracking_orders {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
  // background-color: white;
  margin: 1rem;
  // padding: 1rem;
  flex-direction: column;
}
.tracking_orders_title {
  display: flex;
  background-color: white;
  margin-bottom: 1rem;
  padding: 0rem 0.5rem 1rem 1rem;
  // margin-bottom: 1rem;
  // border-bottom: 1px solid rgb(238, 230, 230);
  justify-content: space-between;
  align-items: center;
  // padding-bottom: 1rem;
  width: 100%;
}
.tracking_orders_title > div {
  margin-right: 0.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.tracking_orders_status {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  // padding-bottom: 1rem;
  margin-top: 1rem;
  // border-bottom: 1px solid rgb(238, 230, 230);
  flex-direction: column;
}
.tracking_orders_status > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.steps-content {
  min-height: 200px;
  margin-top: 1rem;
  // padding-top: 80px;
  text-align: center;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.steps-action {
  margin-top: 1rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_status_steps {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_status_steps_fix {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.steps {
  max-width: 100%;
  width: 100%;
  //   width: 50rem;
  //   background-color: red;
  overflow: auto;
}
.tracking_orders_status_steps_detail {
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_status_steps_detail > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_status_steps_detail_fix {
  display: flex;
  justify-content: flex-end;
  // margin-top: 1rem;
  // background-color: red;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_status_steps_detail_fix > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_status_steps_detail_timeline {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  //   margin-top: 1rem;
  //   border: 1px solid rgb(238, 230, 230);
  width: 100%;
  flex-direction: column;
}
.tracking_orders_status_steps_detail_timeline_title {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  //   border: 1px solid rgb(238, 230, 230);
  padding: 1rem;
  background-color: #fafafa;
  color: black;
  font-weight: 600;
}
.tracking_orders_status_steps_detail_timeline_item {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_status_steps_detail_timeline_item > div {
  margin-bottom: 1rem;
}
.tracking_orders_status_steps_detail_timeline_item_time {
  display: flex;
  justify-content: flex-end;
  // opacity: 0;
  display: none;
  align-items: center;
  padding: 1rem 1rem 1rem 1rem;
  //   border: 1px solid rgb(238, 230, 230);
  width: 100%;
}
.tracking_orders_status_steps_detail_timeline_item_time_active {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: blue;
  display: none;
  // background-color: red;
  // height: 50vh;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 1rem 1rem 1rem;
  //   border: 1px solid rgb(238, 230, 230);
  width: 100%;
}
.tracking_orders_row {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.tracking_orders_row_col {
  width: 100%;

  // margin-bottom: 1rem;
}
.tracking_orders_row_col_right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_row_col_right_notes {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  background-color: white;
  // padding-bottom: 0.5rem;
}
.tracking_orders_row_col_right_notes_child {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid rgb(236, 230, 230);
  padding-bottom: 0.75rem;
}
.tracking_orders_row_col_right_notes_child_fix_parent {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 0.75rem;
}
.tracking_orders_row_col_right_notes_child_fix_parent > div {
  margin-right: 0.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.tracking_orders_row_col_right_notes_child > div {
  margin-right: 0.5rem;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.tracking_orders_row_col_right_notes_item {
  display: flex;
  justify-content: flex-start;
  margin-top: 0.75rem;
  align-items: center;
  width: 100%;
  flex-direction: column;
}

.tracking_orders_row_col_right_notes_parent {
  display: flex;
  border-bottom: 1px solid rgb(224, 207, 207);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.tracking_orders_row_col_right_notes_child_bottom {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  // width: 100%;
}
.tracking_orders_row_col_right_notes_child_bottom > div {
  margin-left: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  color: #1890ff;
}
.tracking_orders_row_col_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_row_col_left_top {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_row_col_left_top_above {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgb(224, 207, 207);
  width: 100%;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.tracking_orders_row_col_left_top_above_fix {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  color: black;
  font-weight: 600;
  font-size: 1rem;
}
.tracking_orders_row_col_left_top_bottom {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.tracking_orders_row_col_left_top_bottom_left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_row_col_left_top_bottom_left_col {
  // margin-bottom: 1rem;
  width: 100%;
}
.tracking_orders_row_col_left_top_bottom_left > div {
  margin-right: 1rem;
}
.tracking_orders_row_col_left_top_bottom_left_metal {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  // width: 100%;
  flex-direction: column;
}
.tracking_orders_row_col_left_top_bottom_left_metal > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.tracking_orders_row_col_left_right {
  display: flex;
  justify-content: flex-end;
  // align-items: center;
  // flex-direction: column;
  width: 100%;
}
.tracking_orders_row_col_left_right_col {
  // margin-bottom: 1rem;
  // flex-direction: column;
  width: 100%;
}
.tracking_orders_row_col_left_top_button_child {
  color: #ff8c21;
  border: 1px solid #ff8c21;
  height: 2.25rem;
  border-radius: 0.5rem;
  width: 7.5rem;
}
.tracking_orders_row_col_left_bottom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  margin-top: 1rem;
  padding: 1rem;
  width: 100%;
  flex-direction: column;
}
.tracking_orders_row_col_left_bottom_timeline_fix {
  display: flex;
  justify-content: space-between;
  // align-items: center;
  width: 100%;
}
.tracking_orders_row_col_left_bottom_timeline {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.aop_drawer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.aop_drawer > div {
  margin-right: 1rem;
}
.aop_drawer_right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  flex-direction: column;
}
.aop_drawer_right > div {
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.order_logs {
  display: flex;
  padding: 1rem 1rem 0 1rem;
  background-color: white;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.order_logs > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}
.action_required {
  display: flex;
  transition: all 0.3s;
  justify-content: center;
  padding: 0.5rem 0rem;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border: 1px solid rgb(233, 223, 223);
}
.action_required_active {
  display: flex;
  justify-content: center;
  padding: 0.5rem 0rem;
  align-items: center;

  cursor: pointer;
  width: 100%;
  border: 1px solid #ff5f02;
}
.action_required:hover {
  display: flex;
  cursor: pointer;

  justify-content: center;
  padding: 0.5rem 0rem;
  align-items: center;
  width: 100%;
  border: 1px solid #ff5f02;
}
// .action_required_active:hover > div {
//   color:#FF5F02;
//   font-size: 1rem;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }
.action_required:hover > div {
  color: #ff5f02;

  display: flex;
  justify-content: center;
  align-items: center;
}

.action_required_title:hover {
  color: #ff5f02;
  display: flex;
  justify-content: center;
  align-items: center;
}
.action_required_title_active {
  color: #ff5f02;

  display: flex;
  justify-content: center;
  align-items: center;
}
.assign_popover {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-direction: column;
}
.assign_popover > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
}
.assign_popover > div:hover {
  transition: all 0.3s;
  background-color: rgb(241, 232, 232);
}
.assign_popover > div > div {
  margin-right: 0.5rem;
  font-size: 1rem;
}
.more_action_col {
  margin-left: 1rem;
  width: 14.5rem;
  height: 2.5rem;
  font-size: 1rem;
  cursor: pointer;
  background-color: white;
  border: 1px solid rgb(238, 227, 227);
  display: flex !important;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
}
.more_action_col:hover {
  border: 1px solid #ff0000;
  color: #ff0000;
  transition: all 0.3s;
}
