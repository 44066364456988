.newsletter_manager {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    flex-direction: column;
}
.newsletter_manager_title {
    display: flex;
    cursor: pointer;
    // background-color: red;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    width: 100%;
}
.newsletter_manager_title > div {
    color: black;
    font-size: 1rem;
    font-weight: 600;
    margin-right: 1rem;
}
.newsletter_manager_bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    flex-direction: column;
    padding: 1rem 1rem 0 1rem;
    background-color: white;
}
.newsletter_manager_bottom_row {
    display: flex;
    justify-content: space-between;
    // align-items: center;
    width: 100%;
}
.newsletter_manager_bottom_row_col {
    width: 100%;
    // margin-bottom: 1rem;
}
.newsletter_manager_bottom_row_col_title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.newsletter_manager_bottom_row_col_input {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.newsletter_manager_bottom_row_col_input_child {
    width: 100%;
}
.newsletter_manager_button {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 15px;
}
.newsletter_manager_button_cancel {
    margin-right: 1rem;
}
.newsletter_manager_bottom_row_col_recipient {
    color: #0A65FF;
    cursor: pointer;
    width: max-content;
}
.modal_recipient {
    width: 100%;
    max-width: 100%;
    overflow: auto;
}
.modal_recipient_parent {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    flex-direction: column;
}
.modal_recipient_parent_top {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    width: 100%;
    margin-bottom: 1rem;
}
.modal_recipient_parent_top_input {
    width: 100%;
}
.modal_recipient_parent_table {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    border: 1px solid rgb(218, 208, 208);
    width: 100%;
    max-width: 100%;
    overflow: auto;
    background-color: white;

}
.modal_recipient_parent_table_child {
    width: 100%;
    background-color: white;
}
.modal_recipient_button {
    display: flex;
    margin-top: 1rem;
    justify-content: flex-end;
    align-content: center;
    width: 100%;
}
.modal_recipient_button_cancel {
    margin-right: 1rem;
}