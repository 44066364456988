.news_manager {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem;
    padding: 1rem;
    background-color: white;
    flex-direction: column;
}
.news_manager_title {
    display: flex;
    border-bottom: 1px solid rgb(214, 198, 198);
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.news_manager_title_row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.news_manager_title_row_col {
    width: 100%;
    margin-bottom: 1rem;
}
.news_manager_title_row_col_left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.news_manager_title_row_col_right {
    display: flex;
    cursor: pointer;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
}
.news_manager_title_row_col_left > div {
    color: black;
    font-size: 1rem;
    font-weight: 600;
}
.news_manager_select {
    display: flex;
    margin: 1rem 0;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}.news_manager_table {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    background-color: white;
    max-width: 100%;
    overflow: auto;
    border: 1px solid rgb(206, 194, 194);
}
.news_manager_table_child {
    width: 100%;
    background-color: white;
}
.sort {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    width: 100%;
}
.sort > div {
    color: #1890FF;
    font-size: 1rem;
}
.title {
    cursor: pointer;
    color: #1890FF;
}
.watched {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    // width: 100%;
    color: black;
    margin: 1rem 0 0 0;
    font-weight: 600;
    font-size: 1rem;
}
.watched_parent {
    display: flex;
    max-width: 100%;
    overflow: auto;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}
.watched_parent > div {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   width: 100%;
  margin-right: 1rem;
}
.watched_parent_item {
    margin-top: 1rem;
}
// .watched_parent_item {
//     display: flex;
//     width: 100%;
//     margin-right: 1rem;
//     margin-top: 1rem;

//     justify-content: space-between;
//     align-items: center;

// }
.button_seen {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-top: 1rem;
}
